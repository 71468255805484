import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";

export function fetchStationDeviceDockList(params) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/dock/list`, {headers, params: params});
}


export function fetchStationDeviceInfo(stationDeviceId) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/${stationDeviceId}`, {headers, params: null});
}
