import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchWaypointList(params) {
  return axios.get(baseUrl + baseApiVersion + `/waypoint/list`, {
    headers,
    params,
  });
}

export function fetchWaypointCreate(data) {
  return axios.post(baseUrl + baseApiVersion + `/waypoint`, data, { headers });
}

export function fetchWaypointInfo(waypointId) {
  return axios.get(baseUrl + baseApiVersion + `/waypoint/${waypointId}`, {
    headers,
  });
}

export function fetchWaypointDelete(waypointId) {
  return axios.delete(baseUrl + baseApiVersion + `/waypoint/${waypointId}`, {
    headers,
  });
}

export function fetchWaypointTemplateDownloadUrl() {
  return baseUrl + baseApiVersion + `/waypoint/template/download`;
}

export function fetchWaypointUpload(data) {
  headers["Content-Type"] = "multipart/form-data";
  return axios.post(baseUrl + baseApiVersion + `/waypoint/upload`, data, { headers });
}


export function getWaypointGimbalPitchList(){
  return [
      {id : "None",show:"None"},
      {id : "Gimbal0",show:"0",},
      {id : "Gimbal45",show:"45",},
      {id : "Gimbal90",show:"90",},
  ]
}
