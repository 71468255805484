import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";



export function fetchMissionLocationList(params) {
  return axios.get(baseUrl + baseApiVersion + `/mission/location/list`, {
    headers,
    params,
  });
}

