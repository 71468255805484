import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";


export function fetchMissionCreate(data) {
    return  axios.post(baseUrl + baseApiVersion + `/mission`, data, { headers });
  }

  export function fetchMissionList(params) {
    return axios.get(baseUrl + baseApiVersion + `/mission/list`, {
      headers,
      params,
    });
  }

  export function fetchMissionInfo(missionId) {
    return axios.get(baseUrl + baseApiVersion + `/mission/${missionId}`, {
      headers,
    });
  }

  
export function fetchMissionUpdate(data) {
  return  axios.put(baseUrl + baseApiVersion + `/mission/update`, data, { headers });
}

export function fetchMissionDelete(missionId) {
  return axios.delete(baseUrl + baseApiVersion + `/mission/${missionId}`, {
    headers,
  });
}
export function fetchMissionTemplateDownloadUrl() {
  return baseUrl + baseApiVersion + `/mission/template/download`;
}

export function fetchMissionUpload(data) {
  headers["Content-Type"] = "multipart/form-data";
  return axios.post(baseUrl + baseApiVersion + `/mission/upload`, data, { headers });
}

export function getMissionGimbalPitchList(){
  return [
      {id : "None",show:"None"},
      {id : "Gimbal0",show:"0",},
      {id : "Gimbal45",show:"45",},
      {id : "Gimbal90",show:"90",},
  ]
}

export function getMissionCameraList(){
  return axios.get(baseUrl + baseApiVersion + `/mission/cameraList`, {
    headers,
  }); 
}
   